import { createHelpers } from 'vuex-map-fields'
const axios = require('axios').default
const { getField, updateField } = createHelpers({
    getterType: 'getField',
    mutationType: 'updateField'
})

const getDefaultState = () => {
    return {
        id: null,
        name: null,
        practices: [],
        error_messages: [],
        practice: null,
        _saving: false,
        _loading: false
    }
}

const templates = {

    namespaced: true,
    strict: true,

    state: getDefaultState(),

    getters: {
        getField
    },

    mutations: {
        updateField,

        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        },

        SET_TEMPLATE (state, payload) {
            state.id = payload.id
            state.name = payload.name
            state.practices = payload.practices
        },

        SET_SAVING (state, payload) {
            state._saving = payload
        },

        SET_LOADING (state, payload) {
            state._loading = payload
        },

        SET_LOADING_TEMPLATES (state, payload) {
            state._loading_practices = payload
        },

        SET_ERROR_MESSAGES (state, payload) {
            state.error_messages = []
            for (const [obj] of Object.values(payload)) {
                state.error_messages.push(obj)
            }
        },
        SET_PRACTICE (state, payload) {
            state.practices.push({ tp_id: payload.response.data.resource.id, id: payload.practice.id, name: payload.practice.name })
        },
        UNSET_PRACTICE (state, payload) {
            state.practices.splice(payload, 1)
        }
    },

    actions: {

        // MISC
        resetState ({ commit }) {
            commit('RESET_STATE')
        },

        pluckResources ({ commit }, excludedIds) {
            commit('SET_LOADING_TEMPLATES', true)

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/templates/pluck',
                    params: { excluded_ids: [excludedIds] || null }
                }).then(response => {
                    commit('SET_LOADING_TEMPLATES', false)
                    resolve(response.data)
                }).catch(error => {
                    commit('SET_LOADING_TEMPLATES', false)
                    reject(error)
                })
            })
        },

        fetchResources ({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/templates'
                }).then(response => {
                    commit('SET_LOADING_TEMPLATES', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        fetchResource ({ commit }, id) {
            commit('RESET_STATE')

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/templates/show/' + id
                }).then(response => {
                    commit('SET_TEMPLATE', response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        storeResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    id: state.id || null,
                    name: state.name,
                    practices: state.practices || []
                }

                axios({
                    method: 'post',
                    url: '/ehr/templates/store',
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },

        updateResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    id: state.id || null,
                    name: state.name,
                    practices: state.practices || []
                }

                axios({
                    method: 'patch',
                    url: '/ehr/templates/update/' + state.id,
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },

        deleteResource ({ state }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: '/ehr/templates/delete/' + state.id
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        storeVinculation ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    practice_id: state.practice.id,
                    template_id: state.id
                }
                axios({
                    method: 'post',
                    url: '/ehr/templates/practices/store',
                    data: resource
                })
                    .then(response => {
                        commit('SET_PRACTICE', { practice: state.practice, response: response })
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },
        deleteVinculation ({ state, commit }, index) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: '/ehr/templates/practices/delete/' + state.practice.tp_id
                }).then(response => {
                    commit('UNSET_PRACTICE', index)
                    commit('SET_LOADING', false)
                    resolve(response.data)
                }).catch(error => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR_MESSAGES', error.response.data.errors)
                    reject(error)
                })
            })
        }
    }
}

export default templates

import { createHelpers } from 'vuex-map-fields'
const axios = require('axios').default
const { getField, updateField } = createHelpers({
    getterType: 'getField',
    mutationType: 'updateField'
})

const getDefaultState = () => {
    return {
        id: null,
        name: null,
        variables: [],
        variable: null,
        error_messages: [],

        _saving: false,
        _loading: false
    }
}

const practices = {

    namespaced: true,
    strict: true,

    state: getDefaultState(),

    getters: {
        getField
    },

    mutations: {
        updateField,

        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        },

        SET_PRACTICE (state, payload) {
            state.id = payload.id
            state.name = payload.name
            state.variables = payload.variables
        },

        SET_SAVING (state, payload) {
            state._saving = payload
        },

        SET_LOADING (state, payload) {
            state._loading = payload
        },

        SET_LOADING_PRACTICES (state, payload) {
            state._loading_practices = payload
        },

        SET_ERROR_MESSAGES (state, payload) {
            state.error_messages = []
            for (const [obj] of Object.values(payload)) {
                state.error_messages.push(obj)
            }
        },
        SET_VARIABLE (state, payload) {
            state.variables.push({ ehr_practice_variable_id: payload.response.data.resource.id, id: payload.variable.id, name: payload.variable.name })
        },
        UNSET_VARIABLE (state, payload) {
            state.variables.splice(payload, 1)
        }
    },

    actions: {

        // MISC
        resetState ({ commit }) {
            commit('RESET_STATE')
        },

        pluckResources ({ commit }, excludedIds) {
            commit('SET_LOADING_PRACTICES', true)

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/practices/pluck',
                    params: { excluded_ids: [excludedIds] || null }
                }).then(response => {
                    commit('SET_LOADING_PRACTICES', false)
                    resolve(response.data)
                }).catch(error => {
                    commit('SET_LOADING_PRACTICES', false)
                    reject(error)
                })
            })
        },

        fetchResources ({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/practices'
                }).then(response => {
                    commit('SET_LOADING_PRACTICES', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        fetchResource ({ commit }, id) {
            commit('RESET_STATE')

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/practices/show/' + id
                }).then(response => {
                    commit('SET_PRACTICE', response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        storeResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    id: state.id || null,
                    name: state.name,
                    variables: state.variables || []
                }

                axios({
                    method: 'post',
                    url: '/ehr/practices/store',
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },

        updateResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    id: state.id || null,
                    name: state.name,
                    variables: state.variables || []
                }

                axios({
                    method: 'patch',
                    url: '/ehr/practices/update/' + state.id,
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },

        deleteResource ({ state }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: '/ehr/practices/delete/' + state.id
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        storeVinculation ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    variable_id: state.variable.id,
                    practice_id: state.id
                }
                axios({
                    method: 'post',
                    url: '/ehr/practices/variables/store',
                    data: resource
                })
                    .then(response => {
                        commit('SET_VARIABLE', { variable: state.variable, response: response })
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },
        deleteVinculation ({ state, commit }, index) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: '/ehr/practices/variables/delete/' + state.variable.ehr_practice_variable_id
                }).then(response => {
                    commit('UNSET_VARIABLE', index)
                    commit('SET_LOADING', false)
                    resolve(response.data)
                }).catch(error => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR_MESSAGES', error.response.data.errors)
                    reject(error)
                })
            })
        }
    }
}

export default practices

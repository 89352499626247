import moment from 'moment'

export default {
    // Format number to ARS currency
    currency: (value) => {
        // if (!value) return ''
        var formatter = new Intl.NumberFormat('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 2
        })
        return formatter.format(value)
    },

    cuit: (value) => {
        return value.substr(0, 2) + '-' + value.substr(2, 8) + '-' + value.substr(10, 1)
    },
    voucherSalePoint: (value) => {
        return String(value).padStart(5, '0')
    },
    voucherLetter: (value) => {
        return String(value).toUpperCase()
    },
    voucherNumber: (value) => {
        return String(value).padStart(8, '0')
    },
    age: (value) => {
        return moment().diff(moment(value), 'years')
    },
    shortDate: (value) => {
        return moment(value).format('DD/MM/YYYY ')
    },

    shortDateTime: (value) => {
        return moment(value).format('DD/MM/YYYY HH:mm')
    },
    shortHour: (value) => {
        return moment(value, 'hh:mm:ss').format('HH:mm')
    },

    friendlyDate: (value) => {
        return moment(value).format('LL')
    },

    friendlyDateTime: (value) => {
        return moment(value).format('LLL')
    },

    fromNow: (value) => {
        return moment(value).fromNow()
    },

    shortTime: (value) => {
        return moment(value).format('HH:mm ')
    },

    // Format number to non decimal percentage
    percentage: (value, decimals) => {
        if (!value) value = 0
        if (!decimals) decimals = 0

        return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals) + '%'
    }
}

import { createHelpers } from 'vuex-map-fields'
const axios = require('axios').default
const { getField, updateField } = createHelpers({
    getterType: 'getField',
    mutationType: 'updateField'
})

const getDefaultState = () => {
    return {
        id: null,
        name: null,
        min_value: null,
        max_value: null,
        reference: null,
        measurement_unit_id: null,
        measurement_unit_name: null,
        measurement_unit: null,
        symbol: null,
        options: [],
        option: null,
        error_messages: [],

        _saving: false,
        _loading: false
    }
}

const variables = {

    namespaced: true,
    strict: true,

    state: getDefaultState(),

    getters: {
        getField
    },

    mutations: {
        updateField,

        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        },

        SET_VARIABLE (state, payload) {
            state.id = payload.id
            state.name = payload.name
            state.min_value = payload.min_value
            state.max_value = payload.max_value
            state.reference = payload.reference
            state.symbol = payload.symbol
            state.measurement_unit_name = payload.measurement_unit
            if (payload.measurement_unit) {
                state.measurement_unit = {
                    id: payload.measurement_unit_id,
                    name: payload.measurement_unit
                }
            } else {
                state.measurement_unit = null
            }
            state.measurement_unit_id = payload.measurement_unit_id
            state.options = payload.options
        },

        SET_SAVING (state, payload) {
            state._saving = payload
        },

        SET_LOADING (state, payload) {
            state._loading = payload
        },

        SET_LOADING_VARIABLES (state, payload) {
            state._loading_variables = payload
        },

        SET_ERROR_MESSAGES (state, payload) {
            state.error_messages = []
            for (const [obj] of Object.values(payload)) {
                state.error_messages.push(obj)
            }
        },
        SET_OPTION (state, payload) {
            state.options.push({ vo_id: payload.response.data.resource.id, id: payload.option.id, name: payload.option.name })
        },
        UNSET_OPTION (state, payload) {
            state.options.splice(payload, 1)
        }
    },

    actions: {

        // MISC
        resetState ({ commit }) {
            commit('RESET_STATE')
        },

        pluckResources ({ commit }, excludedIds) {
            commit('SET_LOADING_VARIABLES', true)

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/variables/pluck',
                    params: { excluded_ids: [excludedIds] || null }
                }).then(response => {
                    commit('SET_LOADING_VARIABLES', false)
                    resolve(response.data)
                }).catch(error => {
                    commit('SET_LOADING_VARIABLES', false)
                    reject(error)
                })
            })
        },

        fetchResources ({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/variables'
                }).then(response => {
                    commit('SET_LOADING_VARIABLES', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        fetchResource ({ commit }, id) {
            commit('RESET_STATE')

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/variables/show/' + id
                }).then(response => {
                    commit('SET_VARIABLE', response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        storeResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    id: state.id || null,
                    name: state.name,
                    min_value: state.min_value,
                    max_value: state.max_value,
                    reference: state.reference,
                    measurement_unit_id: state.measurement_unit?.id,
                    measurement_unit: state.measurement_unit,
                    options: state.options || []
                }

                axios({
                    method: 'post',
                    url: '/ehr/variables/store',
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },

        updateResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    id: state.id || null,
                    name: state.name,
                    min_value: state.min_value,
                    max_value: state.max_value,
                    reference: state.reference,
                    measurement_unit_id: state.measurement_unit?.id || null,
                    measurement_unit: state.measurement_unit,
                    options: state.options || []
                }

                axios({
                    method: 'patch',
                    url: '/ehr/variables/update/' + state.id,
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },

        deleteResource ({ state }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: '/ehr/variables/delete/' + state.id
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        storeVinculation ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    option_id: state.option.id,
                    variable_id: state.id
                }
                axios({
                    method: 'post',
                    url: '/ehr/variables/options/store',
                    data: resource
                })
                    .then(response => {
                        commit('SET_OPTION', { option: state.option, response: response })
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },
        deleteVinculation ({ state, commit }, index) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: '/ehr/variables/options/delete/' + state.option.vo_id
                }).then(response => {
                    commit('UNSET_OPTION', index)
                    commit('SET_LOADING', false)
                    resolve(response.data)
                }).catch(error => {
                    commit('SET_LOADING', false)
                    commit('SET_ERROR_MESSAGES', error.response.data.errors)
                    reject(error)
                })
            })
        }
    }
}

export default variables

import { createHelpers } from 'vuex-map-fields'
const axios = require('axios').default
const { getField, updateField } = createHelpers({
    getterType: 'getField',
    mutationType: 'updateField'
})

const getDefaultState = () => {
    return {
        id: null,
        name: null,
        error_messages: [],

        _saving: false,
        _loading: false
    }
}

const options = {

    namespaced: true,
    strict: true,

    state: getDefaultState(),

    getters: {
        getField
    },

    mutations: {
        updateField,

        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        },

        SET_OPTION (state, payload) {
            state.id = payload.id
            state.name = payload.name
        },

        SET_SAVING (state, payload) {
            state._saving = payload
        },

        SET_LOADING (state, payload) {
            state._loading = payload
        },

        SET_LOADING_OPTIONS (state, payload) {
            state._loading_options = payload
        },

        SET_ERROR_MESSAGES (state, payload) {
            state.error_messages = []
            for (const [obj] of Object.values(payload)) {
                state.error_messages.push(obj)
            }
        }
    },

    actions: {

        // MISC
        resetState ({ commit }) {
            commit('RESET_STATE')
        },

        pluckResources ({ commit }, excludedIds) {
            commit('SET_LOADING_OPTIONS', true)

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/options/pluck',
                    params: { excluded_ids: [excludedIds] || null }
                }).then(response => {
                    commit('SET_LOADING_OPTIONS', false)
                    resolve(response.data)
                }).catch(error => {
                    commit('SET_LOADING_OPTIONS', false)
                    reject(error)
                })
            })
        },

        fetchResources ({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/options'
                }).then(response => {
                    commit('SET_LOADING_OPTIONS', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        fetchResource ({ commit }, id) {
            commit('RESET_STATE')

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/ehr/options/show/' + id
                }).then(response => {
                    commit('SET_OPTION', response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        storeResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    id: state.id || null,
                    name: state.name
                }

                axios({
                    method: 'post',
                    url: '/ehr/options/store',
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },

        updateResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                const resource = {
                    id: state.id || null,
                    name: state.name
                }

                axios({
                    method: 'patch',
                    url: '/ehr/options/update/' + state.id,
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },

        deleteResource ({ state }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: '/ehr/options/delete/' + state.id
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default options

export default [
    // **********************************************************************
    // AFIP / Vouchers
    // **********************************************************************
    {
        name: 'afip-vouchers-view',
        path: 'afip/vouchers/:id',
        component: () => import('@/components/afip/vouchers/ShowPage'),
        props: true,
        meta: {
            title: 'Comprobante',
            auth: {
                roles: 'admin'
            }
        }
    },
    // **********************************************************************
    // AFIP / Invoices
    // **********************************************************************
    {
        name: 'afip-invoices-list',
        path: 'afip/invoices',
        component: () => import('@/components/afip/invoices/IndexPage'),
        meta: {
            title: 'Facturas',
            auth: {
                roles: 'admin'
            }
        }
    },
    {
        name: 'afip-invoices-create',
        path: 'afip/invoices/create',
        component: () => import('@/components/afip/invoices/CreatePage'),
        props: true,
        meta: {
            title: 'Crear Factura',
            auth: {
                roles: 'admin'
            }
        }
    },
    {
        name: 'afip-invoices-edit',
        path: 'afip/invoices/:id/edit',
        component: () => import('@/components/afip/invoices/CreatePage'),
        props: true,
        meta: {
            title: 'Editar Factura',
            auth: {
                roles: 'admin'
            }
        }
    },
    // {
    //     name: 'afip-invoices-view',
    //     path: 'afip/invoices/:id',
    //     component: () => import('@/components/afip/vouchers/ShowPage'),
    //     props: true,
    //     meta: {
    //         title: 'Factura',
    //         auth: {
    //             roles: 'admin'
    //         }
    //     }
    // },
    // **********************************************************************
    // AFIP / Credit Notes
    // **********************************************************************
    {
        name: 'afip-credit-note-list',
        path: 'afip/credit-note',
        component: () => import('@/components/afip/creditNotes/IndexPage'),
        meta: {
            title: 'Listado',
            auth: {
                roles: 'admin'
            }
        }
    },
    {
        name: 'afip-credit-note-create',
        path: 'afip/credit-note/create',
        component: () => import('@/components/afip/creditNotes/CreatePage'),
        props: true,
        meta: {
            title: 'Crear Nota de Crédito',
            auth: {
                roles: 'admin'
            }
        }
    },
    {
        name: 'afip-credit-note-edit',
        path: 'afip/credit-note/:id/edit',
        component: () => import('@/components/afip/creditNotes/CreatePage'),
        props: true,
        meta: {
            title: 'Editar Nnota de Crédito',
            auth: {
                roles: 'admin'
            }
        }
    },
    // {
    //     name: 'afip-credit-note-view',
    //     path: 'afip/credit-note/:id',
    //     component: () => import('@/components/afip/vouchers/ShowPage'),
    //     props: true,
    //     meta: {
    //         title: 'Nota de Crédito',
    //         auth: {
    //             roles: 'admin'
    //         }
    //     }
    // },
    // **********************************************************************
    // AFIP / Debit Notes
    // **********************************************************************
    {
        name: 'afip-debit-note-list',
        path: 'afip/debit-note',
        component: () => import('@/components/afip/debitNotes/IndexPage'),
        meta: {
            title: 'Notas de Débito',
            auth: {
                roles: 'admin'
            }
        }
    },
    {
        name: 'afip-debit-note-create',
        path: 'afip/debit-note/create',
        component: () => import('@/components/afip/debitNotes/CreatePage'),
        props: true,
        meta: {
            title: 'Crear Nota de Débito',
            auth: {
                roles: 'admin'
            }
        }
    },
    {
        name: 'afip-debit-note-edit',
        path: 'afip/debit-note/:id/edit',
        component: () => import('@/components/afip/debitNotes/CreatePage'),
        props: true,
        meta: {
            title: 'Nota de Débito',
            auth: {
                roles: 'admin'
            }
        }
    },
    // {
    //     name: 'afip-debit-note-view',
    //     path: 'afip/debit-note/:id',
    //     component: () => import('@/components/afip/vouchers/ShowPage'),
    //     props: true,
    //     meta: {
    //         title: 'Nota de Débito',
    //         auth: {
    //             roles: 'admin'
    //         }
    //     }
    // },
    // **********************************************************************
    // AFIP / Companies
    // **********************************************************************
    {
        name: 'afip-companies-list',
        path: 'afip/companies',
        component: () => import('@/components/afip/companies/IndexPage'),
        meta: {
            title: 'Empresas',
            auth: {
                roles: 'admin'
            }
        }
    },
    {
        name: 'afip-companies-create',
        path: 'afip/companies/create',
        component: () => import('@/components/afip/companies/CreatePage'),
        props: true,
        meta: {
            title: 'Crear Empresa',
            auth: {
                roles: 'admin'
            }
        }
    },
    {
        name: 'afip-companies-view',
        path: 'afip/companies/:id',
        component: () => import('@/components/afip/companies/ShowPage'),
        props: true,
        meta: {
            title: 'Empresa',
            auth: {
                roles: 'admin'
            }
        }
    }
]

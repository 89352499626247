import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

const axios = require('axios').default

Vue.use(Vuex)

const appointments = {
    namespaced: true,
    strict: true,

    state: {
        filters: {
            statuses: [1, 2, 3, 4, 5],
            calendars: [],
            reasons: [],
            start_time: moment().format('YYYY-MM-DD'),
            refresh_rate: 30
        },
        statuses: []
    },

    getters: {
    },

    mutations: {
        SET_STATUSES (state, payload) {
            state.statuses = payload
        },
        SET_FILTERS_STATUSES (state, payload) {
            state.filters.statuses = payload
        },
        SET_FILTERS_CALENDARS (state, payload) {
            state.filters.calendars = payload
        },
        SET_FILTERS_REASONS (state, payload) {
            state.filters.reasons = payload
        },
        SET_FILTERS_START_TIME (state, payload) {
            state.filters.start_time = payload
        }
    },

    actions: {
        setFilterStartTime ({ commit }, data) {
            commit('SET_FILTERS_START_TIME', data)
        },
        setFilterReasons ({ commit }, data) {
            commit('SET_FILTERS_REASONS', data)
        },
        setFilterCalendars ({ commit }, data) {
            commit('SET_FILTERS_CALENDARS', data)
        },
        setFilterStatuses ({ commit }, data) {
            commit('SET_FILTERS_STATUSES', data)
        },
        fetchStatuses ({ commit }) {
            axios({
                method: 'get',
                url: '/appointments/statuses'
            }).then(response => {
                commit('SET_STATUSES', response.data)
            }).catch(error => {
                console.log(error)
            }).then(response => {
                //
            })
        },
        changeStatus ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'put',
                    url: '/appointments/' + data.id + '/status',
                    data: { status: data.status }
                })
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}

export default appointments

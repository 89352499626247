<template>
    <div></div>
</template>

<script>
export default {
    name: 'MyGenericFilter',
    props: ['props']
}
</script>

import { createHelpers } from 'vuex-map-fields'
const axios = require('axios').default

const { getField, updateField } = createHelpers({
    getterType: 'getField',
    mutationType: 'updateField'
})

const getDefaultState = () => {
    return {
        id: null,
        name: null,
        description: null,
        slug: null,
        color: '#FFF',
        parent: null,
        order: null,
        title: null,

        nested_categories: [],
        error_messages: [],

        _saving: false,
        _loading: false,
        _loading_categories: false
    }
}

const categories = {

    namespaced: true,
    strict: true,

    state: getDefaultState(),

    getters: {
        getField
    },

    mutations: {
        updateField,

        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        },

        SET_CATEGORY (state, payload) {
            state.id = payload.id
            state.name = payload.name
            state.description = payload.description
            state.slug = payload.slug
            state.color = payload.color || '#FFFFFF'
            state.parent = payload.parent
            state.order = payload.order
            state.title = payload.name
        },

        SET_NESTED_CATEGORIES (state, payload) {
            const nest = (items, id = null, link = 'parent_id') =>
                items
                    .reduce((carry, item) => {
                        if (item[link] === id) {
                            carry.push({ ...item, children: nest(items, item.id) })
                        }
                        return carry
                    }, [])

            state.nested_categories = nest(payload)
        },

        SET_SAVING (state, payload) {
            state._saving = payload
        },

        SET_LOADING (state, payload) {
            state._loading = payload
        },

        SET_LOADING_CATEGORIES (state, payload) {
            state._loading_categories = payload
        },

        SET_ERROR_MESSAGES (state, payload) {
            state.error_messages = []
            for (const [obj] of Object.values(payload)) {
                state.error_messages.push(obj)
            }
        }
    },

    actions: {

        // MISC
        resetState ({ commit }) {
            commit('RESET_STATE')
        },

        pluckResources ({ commit }, excludedIds) {
            commit('SET_LOADING_CATEGORIES', true)

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/categories/pluck',
                    params: { excluded_ids: [excludedIds] || null }
                }).then(response => {
                    commit('SET_LOADING_CATEGORIES', false)
                    resolve(response.data)
                }).catch(error => {
                    commit('SET_LOADING_CATEGORIES', false)
                    reject(error)
                })
            })
        },

        fetchResources ({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/categories'
                }).then(response => {
                    commit('SET_NESTED_CATEGORIES', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        fetchResource ({ commit }, id) {
            commit('RESET_STATE')

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/categories/show/' + id
                }).then(response => {
                    commit('SET_CATEGORY', response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        storeResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                let resource = {
                    id: state.id || null,
                    name: state.name,
                    description: state.description,
                    slug: state.slug,
                    color: state.color || null,
                    parent_id: state.parent?.id || null,
                    order: state.order || null
                }

                axios({
                    method: 'post',
                    url: '/categories/store',
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },

        updateResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                let resource = {
                    id: state.id || null,
                    name: state.name,
                    description: state.description,
                    slug: state.slug,
                    color: state.color || null,
                    parent_id: state.parent?.id || null,
                    order: state.order || null
                }

                axios({
                    method: 'patch',
                    url: '/categories/update/' + state.id,
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        },

        deleteResource ({ state }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: '/categories/delete/' + state.id
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default categories

<template>
    <div class="sidenav-divider"></div>
</template>

<script>

export default {
    name: 'sidenav-divider'
}
</script>

// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'
// Core
import Vue from 'vue'
import App from './App'
import router from './router'
import { store } from './store/store'

// Helpers
import _ from 'lodash'

// Misc
import globals from './globals'
import Popper from 'popper.js'

// Filters
import numericFilters from './filters/numerics'
import filesFilters from './filters/files'
import VtGenericFilter from '@/components/partials/VtGenericFilter'

// Components
import BlockUI from 'vue-blockui'
import { BootstrapVue } from 'bootstrap-vue'
import LaddaBtn from '@/vendor/libs/ladda/Ladda'
import Multiselect from 'vue-multiselect'
import { ServerTable, ClientTable } from 'vue-tables-2'

import VueImg from 'v-img'

/* ***************
 * VEE-VALIDATE
 * ***************/
import { ValidationProvider, extend, ValidationObserver, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'

import moment from 'moment'

// moment.tz.setDefault('America/Argentina')
moment.locale('es')
Vue.prototype.$moment = moment
Vue.component('LaddaBtn', LaddaBtn)
// Se activa idioma Español
localize('es', es)

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('Multiselect', Multiselect)

// const moment = require('moment-timezone')
// moment.tz.setDefault('America/Argentina/Buenos_Aires')

const axios = require('axios').default
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' }
axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/api'
Vue.prototype.axios = axios

Vue.use(VueImg, {
    altAsTitle: true
})
Vue.axios = axios
Vue.router = router
Vue.prototype.api_url = process.env.VUE_APP_API_URL
Vue.prototype.api_path = process.env.VUE_APP_API_URL + '/api'
Vue.prototype.image_path = process.env.VUE_APP_IMAGE_URL
Vue.prototype.files_path = process.env.VUE_APP_FILES_URL
Vue.prototype.packageVersion = require('../package.json').version
Vue.use(require('@websanova/vue-auth'), {
    // axios interceptors
    auth: {
        request: function (req, token) {
            // Set Bearer token in header
            this.options.http._setHeaders.call(this, req, { Authorization: 'Bearer ' + token })

            // Set extra data in headers in case of refresh action
            if (req.url === this.options.refreshData.url) {
                this.options.http._setHeaders.call(this, req, { Refresh: this.token('refresh_token') })
                this.options.http._setHeaders.call(this, req, { Expiration: this.token('expires_at') })
            }
        },
        response: function (res) {
            const resData = res.data || {}

            // If new refresh token then set data
            if (resData.refresh_token && resData.expires_at) {
                this.token('refresh_token', resData.refresh_token)
                this.token('expires_at', resData.expires_at)
            }

            // If version provided then update localStorage
            if (resData.version) {
                localStorage.setItem('version', resData.version)
            }

            // If version mismatch, then force App refresh
            let version = this.options.http._getHeaders.call(this, res).version

            if (version && !localStorage.getItem('version')) {
                this.token('version', version)
            } else if (version && localStorage.getItem('version') && localStorage.getItem('version') !== version) {
                this.token('version', version)
                location.reload()
            }

            // If token provided from login, then return it
            if (resData.access_token) {
                return resData.access_token
            }
        }
    },
    http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),

    tokenStore: ['localStorage', 'cookie'],
    tokenDefaultName: 'token',
    // rolesVar: 'can',

    loginData: {
        url: '/login',
        method: 'POST',
        redirect: '/appointments',
        fetchUser: true
    },

    logoutData: {
        url: '/logout',
        method: 'POST',
        redirect: '/',
        makeRequest: false
    },

    refreshData: {
        url: '/refresh',
        method: 'POST',
        enabled: true,
        interval: 15
    },

    fetchData: {
        url: '/user',
        method: 'GET',
        enabled: true
    },

    authRedirect: { name: 'login' },
    forbiddenRedirect: { name: 'not-authorized' },
    notFoundRedirect: { name: '/' }, // Works when auth:false in route

    parseUserData: function (data) {
        return data
    }
})
Vue.auth.perms = function (perm) {
    return this.options.check.call(this, perm, 'can')
}
Vue.auth.roles = function (perm) {
    return this.options.check.call(this, perm, 'roles')
}
Vue.auth.appModules = function (perm) {
    return this.options.check.call(this, perm, 'app_modules')
}

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue, {
    BOverlay: {
        variant: 'white',
        opacity: '0.85',
        blur: '2px',
        spinnerType: 'border',
        spinnerSmall: false,
        rounded: 'sm',
        spinnerVariant: 'primary'
    }
})

const vueTablesDefaultOptions = {
    // filterable: [],
    perPage: 10,
    perPageValues: [10, 25, 50, 100],
    pagination: {
        chunk: 5,
        edge: true
    },
    sortIcon: { base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort' },
    texts: {
        count: 'Mostrando {from} a {to} de {count} registros|{count} registros|Un registro',
        first: 'Primero',
        last: 'Último',
        filter: 'Buscar: ',
        filterPlaceholder: 'Términos a buscar',
        limit: 'Registros: ',
        page: 'Página: ',
        noResults: 'No hay resultados',
        filterBy: 'Filtrar por {column}',
        loading: 'Cargando...',
        defaultOption: 'Seleccionar {column}',
        columns: 'Columnas'
    }
}

Vue.use(ServerTable, vueTablesDefaultOptions, false, 'bootstrap4', {
    genericFilter: VtGenericFilter
})
Vue.use(ClientTable, vueTablesDefaultOptions, false, 'bootstrap4', {
    genericFilter: VtGenericFilter
})

Vue.use(BlockUI)

// Global RTL flag
Vue.mixin({
    data: globals
})

// Filters
Object.keys(numericFilters).forEach(key => Vue.filter(key, numericFilters[key]))
Object.keys(filesFilters).forEach(key => Vue.filter(key, filesFilters[key]))

Vue.prototype.$eventHub = new Vue()
Vue.prototype._ = _

// Set Front Version
store.commit('setPackageVersion', require('../package.json').version)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

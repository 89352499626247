import { createHelpers } from 'vuex-map-fields'
const axios = require('axios').default
const { getField, updateField } = createHelpers({
    getterType: 'getField',
    mutationType: 'updateField'
})

const getDefaultState = () => {
    return {
        file: null,

        files: {
            data: [],
            page: 0,
            count: 0
        },
        pending_files: [],
        allowed_filetypes: [],

        _opt_categories: [],
        _opt_loading_categories: false,

        _saving: false,
        _loading: false,
        error_messages: []
    }
}

const files = {

    namespaced: true,
    strict: true,

    state: getDefaultState(),

    getters: {
        getField,

        allowed_mimes: state => {
            return state.allowed_filetypes.map(e => e.mime).join(',')
        },

        allowed_mimetypes: state => {
            return state.allowed_filetypes.map(e => e.mimetype).join(',')
        }
    },

    mutations: {
        updateField,

        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        },

        SET_OPT_CATEGORIES (state, payload) {
            state._opt_categories = payload
        },

        SET_ALLOWED_FILES (state, payload) {
            state.allowed_filetypes = payload
        },

        SET_FILES_PAGE (state, payload) {
            state.files.page = payload
        },

        SET_OPT_LOADING_CATEGORIES (state, payload) {
            state._opt_loading_categories = payload
        },

        SET_OPT_LOADING_FILES (state, payload) {
            state._opt_loading_files = payload
        },

        SET_PENDING_FILES (state, payload) {
            state.pending_files = payload
        },

        SET_FILES (state, payload) {
            // state.files = payload
            payload.data.forEach((item) => {
                state.files.data.push(item)
            })
            state.files.count += payload.data.length
        },
        RESET_FILES (state, payload) {
            state.files.data = []
            state.files.page = 0
            state.files.count = 0
        },
        SET_FILES_COUNT (state, payload) {
            state.files_count = payload
        },

        SET_FILE (state, payload) {
            state.file = payload
        },

        SET_SAVING (state, payload) {
            state._saving = payload
        },

        SET_ERROR_MESSAGES (state, payload) {
            state.error_messages = []
            for (const [obj] of Object.values(payload)) {
                state.error_messages.push(obj)
            }
        }
    },

    actions: {

        // MISC
        resetState ({ commit }) {
            commit('RESET_STATE')
        },

        setFilesPage ({ commit }, page) {
            commit('SET_FILES_PAGE', page)
        },

        resetFiles ({ commit }) {
            return new Promise((resolve, reject) => {
                commit('RESET_FILES')
                resolve(true)
            })
        },

        fetchCategories ({ commit }) {
            commit('SET_OPT_LOADING_CATEGORIES', true)

            axios({
                method: 'get',
                url: '/categories/pluck'
            }).then(response => {
                commit('SET_OPT_CATEGORIES', response.data)
            }).catch(error => {
                console.log(error)
            }).then(response => {
                commit('SET_OPT_LOADING_CATEGORIES', false)
            })
        },

        fetchAllowedFiles ({ commit }) {
            axios({
                method: 'get',
                url: '/file-types/allowed'
            }).then(response => {
                commit('SET_ALLOWED_FILES', response.data)
            }).catch(error => {
                console.log(error)
            })
        },

        fetchFiles ({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/files',
                    params: {
                        model_name: payload.parent_model || null,
                        model_id: payload.parent_id || null,
                        limit: payload.limit || 10,
                        page: state.files.page + 1,
                        category_id: payload.category_id || null
                    }
                }).then(response => {
                    state.files.page++
                    commit('SET_FILES', response.data)
                    resolve(response.data.count > state.files.count)
                }).catch(error => {
                    console.log(error)
                })
            })
        },

        updateFiles ({ commit }, files) {
            commit('SET_PENDING_FILES', files)
        },

        fetchResource ({ commit }, id) {
            commit('SET_FILE', null)

            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/files/show/' + id
                }).then(response => {
                    commit('SET_FILE', response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        storeResource (payload) {
            return payload.component.uploadHtml5(payload.file)
        },

        deleteResource ({ state, commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: '/files/delete/' + state.file.id
                }).then(response => {
                    commit('SET_FILE', null)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        updateResource ({ state, commit }) {
            commit('SET_SAVING', true)

            return new Promise((resolve, reject) => {
                let resource = {
                    id: state.file.id,
                    title: state.file.title,
                    category_id: state.file.category?.id || null,
                    description: state.file.description
                }

                axios({
                    method: 'patch',
                    url: '/files/update/' + state.file.id,
                    data: resource
                })
                    .then(response => {
                        commit('SET_SAVING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_SAVING', false)
                        commit('SET_ERROR_MESSAGES', error.response.data.errors)
                        reject(error)
                    })
            })
        }
    }
}

export default files


import Vuex from 'vuex'
import FilesStore from '@/components/files/store/files.js'
import CategoriesStore from '@/components/categories/store/categories.js'
import appointments from '@/components/appointments/store/index.js'
import createPersistedState from 'vuex-persistedstate'

import EhrOptionsStore from '@/components/ehr/options/store/options.js'
import EhrTemplatesStore from '@/components/ehr/templates/store/templates.js'
import EhrSectionsStore from '@/components/ehr/sections/store/sections.js'
import EhrPracticesStore from '@/components/ehr/practices/store/practices.js'
import EhrVariablesStore from '@/components/ehr/variables/store/variables.js'

export const store = new Vuex.Store({
    modules: {
        appointments,
        files: FilesStore,
        categories: CategoriesStore,
        options: EhrOptionsStore,
        templates: EhrTemplatesStore,
        sections: EhrSectionsStore,
        variables: EhrVariablesStore,
        practices: EhrPracticesStore
    },
    plugins: [
        createPersistedState()
    ],

    state: {
        packageVersion: '',
        count: 0
    },

    getters: {
        appVersion: (state) => {
            return state.packageVersion
        }
    },

    mutations: {
        increment: state => state.count++,
        decrement: state => state.count--,
        setPackageVersion (state, version) {
            state.packageVersion = version
        }
    },

    actions: {
    }
})

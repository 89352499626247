export default [
    // **********************************************************************
    // RATES ROUTES
    // **********************************************************************
    {
        name: 'rates-list',
        path: 'rates',
        component: () => import('@/components/rates/IndexPage'),
        meta: {
            title: 'Facturas',
            auth: {
                roles: 'admin'
            }
        }
    }
]

<template>
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
        <div class="container-fluid container-p-x">
            <span class="small text-muted float-right my-1"> API: {{ api_version }} / Front: {{ $store.getters.appVersion }}</span>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'app-layout-footer',

    data () {
        return {
            api_version: localStorage.getItem('version')
        }
    },

    methods: {
        getLayoutFooterBg () {
            return `bg-${this.layoutFooterBg}`
        }
    }
}
</script>
